<script setup lang="ts">
import type { Wishlist, WishlistPayload } from '@yescapa-dev/ysc-api-js/legacy'
import { WISHLIST_ADD_EVENT } from '~/utils/analytics/wishlists'
import type { SubmitAction } from '~/types/commons'
import { YSC_API_WISHLIST_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  product: number
}

const props = defineProps<Props>()
const emits = defineEmits<{
  close: []
}>()

const loading = ref(false)
const showCreateModal = ref(false)

const { $errorManager } = useErrorManager()
const wishlistsStore = useWishlistsStore()

const { all: wishlists } = storeToRefs(wishlistsStore)

await useAsyncData('fetch-camper-wishlist', async () => {
  try {
    await wishlistsStore.fetchWishlists()
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_WISHLIST_ERROR })
    }
  }
})

const isInWishlist = (wishlist: Wishlist) => wishlist.ad_set.find(ad => ad.id === props.product)

const addProductToWishlist = async (slug: string) => {
  loading.value = true
  try {
    await wishlistsStore.addToWishlist({ slug, product_id: props.product })
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_WISHLIST_ERROR })
    }
  }
  loading.value = false
}

const { stackSuccess } = useToastsStore()
const removeProductFromWishlist = async (slug: string) => {
  loading.value = true

  try {
    await wishlistsStore.removeFromWishlist({ slug, product_id: props.product })
    await wishlistsStore.fetchWishlists({ refresh: true })
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_WISHLIST_ERROR })
      loading.value = false
      return
    }
  }

  emits('close')
  stackSuccess({
    label: { text: 'components.app_modal_product_remove_wishlist.toast_success', needTranslation: true },
  })
  loading.value = false
}

onMounted(() => {
  const { event } = useGtag()
  event(WISHLIST_ADD_EVENT)
})

const onSubmitEdition: SubmitAction<WishlistPayload> = async ({ form } = {}) => {
  if (!form) {
    return
  }
  try {
    await wishlistsStore.updateOrCreateWishlist({ form: toValue(form), create: true })
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_WISHLIST_ERROR })
    }
    return
  }
  showCreateModal.value = false
}
</script>

<template>
  <div>
    <YscModal
      class="!z-[90]"
      @close="$emit('close')"
    >
      <template #title>
        {{ $t('commons.my_wishlists') }}
      </template>

      <ul class="flex flex-col">
        <li
          v-for="wishlist of wishlists"
          :key="wishlist.id"
          class="flex cursor-pointer items-center justify-between px-6 py-3 hover:bg-gray-200"
          @click="isInWishlist(wishlist) ? removeProductFromWishlist(wishlist.slug) : addProductToWishlist(wishlist.slug)"
        >
          {{ wishlist.name }} ({{ wishlist.ad_set.length }})
          <YscIconsHeart
            class="h-6 w-6 fill-current stroke-current"
            :class="[isInWishlist(wishlist) ? 'text-pink-500' : 'text-gray-300']"
          />
        </li>
      </ul>

      <template #footer>
        <div class="flex justify-end">
          <button
            class="my-2 font-semibold leading-none text-blue-500 hover:underline"
            @click="showCreateModal = true"
          >
            {{ $t('commons.actions.create_wishlist') }}
          </button>
        </div>
      </template>
    </YscModal>

    <AppModalWishlistEdition
      v-if="showCreateModal"
      class="!z-[100]"
      :submit-action="onSubmitEdition"
      @close="showCreateModal = false"
    />
  </div>
</template>
